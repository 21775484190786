<template>
  <modal v-bind="properties"
    @opened="opened"
    @closed="closed">
    <div class="modal-header">
      {{ errorTitle }}
      <button type="button" class="close p-0"
        @click="$modal.hide('error-modal')"
        @touchend="$modal.hide('error-modal')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <b-card no-body>
        <div class="py-3 px-1">{{ errorMessage }}</div>
      </b-card>
    </div>
    <div class="modal-footer">
      <b-button @click="$modal.hide('error-modal')">close</b-button>
    </div>

  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { BCard } from 'bootstrap-vue';

export default {
  name: 'error-modal',
  components: {
    BCard,
  },
  computed: {
    ...mapGetters({
      errorContent: 'GET_ERROR_CONTENT_INFO',
    }),
    errorTitle() {
      const { errorContent } = this;
      return errorContent?.title || '';
    },
    errorMessage() {
      const { errorContent } = this;
      return errorContent?.message || '';
    },
  },
  watch: {
    errorContent() {
      this.$modal.show('error-modal');
    },
  },
  data() {
    return {
      properties: {
        name: 'error-modal',
        classes: ['error-modal'],
        height: 'auto',
        draggable: '.modal-header',
        adaptive: true,
        width: '30%',
        clickToClose: false,
        minWidth: 250,
        resizable: true,
      },
    };
  },
  methods: {
    opened: () => {
      // console.log('opened');
    },
    closed: () => {
      // console.log('closed');
    },
  },
};
</script>

<style>
.error-modal.vm--modal {
  position: absolute;
  box-shadow: 1px 1px 20px black;
  text-align: left;
  direction: ltr;
}
.error-modal .modal-header .close {
  color: #fff;
  text-shadow: none;
  font-size: 2rem;
  line-height: 1rem;
  margin: 0;
}
.error-modal .modal-header {
  cursor: pointer;
  color: #fff;
  background-color: var(--danger);
  margin: 0;
}
.error-modal .modal-body {
  margin: 0;
}
</style>
